import Vue from 'vue';
export default {
  getCalendarBlockerEventModel(event) {
    return [{
      id: event.id,
      eventType: 'BlockerEvent',
      allDay: true,
      start: event.startDate,
      end: event.endDate,
      display: "block",
      customHexColor: event.customHexColor,
      selectedProgress: event.progress ?? [],
      stepIds: event.stepIds ?? [],
      selectedShippers: event.shippers ?? [],
      selectedInstallers: event.installers ?? [],
      backgroundColor: event.customHexColor,
      title: event.title,
      textColor: "white",
      className: ["noPadding", "smMargin", "roundBorder", "blockerEvent"],
      mountCallBack: function (args) {
        args.el.style.backgroundColor = args.event.extendedProps.customHexColor;
        args.el.style.color = 'white'
        if (args.view.type != 'listWeek') {
          args.el.style.display = 'none';
        }
      },
      editable: false
    }, {
      id: event.id,
      eventType: 'BlockerEvent',
      allDay: true,
      start: event.startDate,
      end: event.endDate,
      display: "background",
      backgroundColor: event.customHexColor,
      stepIds: event.stepIds ?? [],
      selectedProgress: event.progress ?? [],
      selectedShippers: event.shippers ?? [],
      selectedInstallers: event.installers ?? [],
      title: event.title,
      className: ["blockerEvent", "noPadding", "noMargin"]
    }];

  },
  getCalendarInstallationModel(event, resources) {
    var defaultEvent = this.getCalendarDefaultEventModel(event);
    if (defaultEvent.installerIds !== null) {
      defaultEvent.installerIds = JSON.parse(defaultEvent.installerIds.toLowerCase().replaceAll("'", '"'));
    }
    if (defaultEvent.resourceIds === null) {
      defaultEvent.resourceIds = ["InstallationEvent.00000000-0000-0000-0000-000000000000"]
    }
    return [{
      ...defaultEvent,
      durationEditable: true,
      installers: resources.filter(installer => defaultEvent.installerIds?.includes(installer.idPublic) ?? [])
    }];
  },
  getCalendarDeliveryEventModel(event, resources) {
    var defaultEvent = this.getCalendarDefaultEventModel(event);
    if (defaultEvent.shipperIds !== null) {
      defaultEvent.shipperIds = JSON.parse(defaultEvent.shipperIds.toLowerCase().replaceAll("'", '"'));
    }
    if (defaultEvent.resourceIds === null) {
      defaultEvent.resourceIds = ["DeliveryEvent.00000000-0000-0000-0000-000000000000"]
    }
    return [{
      ...defaultEvent,
      durationEditable: true,
      shipper: resources.find(shipper => defaultEvent.shipperIds?.includes(shipper.idPublic) ?? [])
    }];
  },
  getCalendarStepEventModel(event) {
    var defaultEvent = this.getCalendarDefaultEventModel(event);
    defaultEvent.stepIds = JSON.parse(defaultEvent.stepIds.toLowerCase().replaceAll("'", '"'));
    if (defaultEvent.stepUserIds != null) {
      defaultEvent.stepUserIds = JSON.parse(defaultEvent.stepUserIds.toLowerCase().replaceAll("'", '"'));
    }
    if(defaultEvent.stepUserIds === null && defaultEvent.hasStepUsers) {
      defaultEvent.resourceIds = [defaultEvent.stepIds[0] + '.00000000-0000-0000-0000-000000000000']
    }
    return [{
      ...defaultEvent,
      durationEditable: true,
    }];
  },
  getCalendarProductionEventModel(event) {
    var defaultEvent = this.getCalendarDefaultEventModel(event);
    defaultEvent.resourceIds = ["ProductionEvent"];
    return [{
      ...defaultEvent,
      durationEditable: false
    }];
  },
  getCalendarUserEventModel(event) {
    var defaultEvent = this.getCalendarDefaultEventModel(event);
    if (defaultEvent.stepIds !== null) {
      defaultEvent.stepIds = JSON.parse(defaultEvent.stepIds.toLowerCase().replaceAll("'", '"'));
    }
    if (defaultEvent.shipperIds !== null) {
      defaultEvent.shipperIds = JSON.parse(defaultEvent.shipperIds.toLowerCase().replaceAll("'", '"'));
    }
    if (defaultEvent.installerIds !== null) {
      defaultEvent.installerIds = JSON.parse(defaultEvent.installerIds.toLowerCase().replaceAll("'", '"'));
    }
    return [{
      ...defaultEvent,
      durationEditable: true
    }];
  },
  getCalendarDefaultEventModel(event) {
    var self = this;
    var eventModel = {
      ...event,
      display: "block",
      backgroundColor: "white",
      textColor: "#495057",
      completed: event.dateCompleted || event.workOrderPercentCompleted == 1 ? true : false,
      selected: false,
      className: ["greyBorder", "noPadding", "smMargin", "roundBorder"],
      mountCallBack: function (args) {
        var overlap = self.getEventOverlap(args.event, args.view.calendar.getEvents());
        if (overlap) {
          args.event.setProp('overlap', overlap);
          args.el.className += ' fc-event-overlap';
        }
      }
    };
    if (eventModel.resourceIds != null) {
      eventModel.resourceIds = JSON.parse(eventModel.resourceIds.toLowerCase().replaceAll("'", '"'));
    }
    return eventModel;
  },
  getCalendarEventModel(event, resources) {
    var eventModelFunctions = {
      BlockerEvent: this.getCalendarBlockerEventModel,
      InstallationEvent: this.getCalendarInstallationModel,
      DeliveryEvent: this.getCalendarDeliveryEventModel,
      StepEvent: this.getCalendarStepEventModel,
      ProductionEvent: this.getCalendarProductionEventModel,
      UserEvent: this.getCalendarUserEventModel
    }
    return eventModelFunctions[event.eventType].call(this, event, resources);
  },
  getEventOverlap(event, calendarEvents) {
    if (event.extendedProps.eventType == 'BlockerEvent') {
      return false;
    }
    if (event.extendedProps.eventType != 'StepEvent' && event.extendedProps.eventType != 'InstallationEvent' && event.extendedProps.eventType != 'DeliveryEvent') {
      return false;
    }
    var blockers = calendarEvents
      .filter(
        (x) =>
          x.extendedProps.eventType == "BlockerEvent" &&
          event.end > x.start &&
          event.start < x.end
      );
    if (event.extendedProps.eventType == "StepEvent") {
      blockers = blockers.filter((x) =>
        x.extendedProps.stepIds.length !== 0 && x.extendedProps.stepIds.find((stepId) =>
          event.extendedProps.stepIds.includes(stepId)
        ).length !== 0
      );
    }
    if (event.extendedProps.eventType == "InstallationEvent") {
      blockers = blockers.filter(
        (x) =>
          x.extendedProps.selectedInstallers.filter((installer) =>
            event.extendedProps.installerIds?.includes(installer)
          ).length ?? 0 != 0
      );
    }
    if (event.extendedProps.eventType == "DeliveryEvent") {
      blockers = blockers.filter(
        (x) =>
          x.extendedProps.selectedShippers.filter(
            (shipper) =>
              event.extendedProps.shipperIds?.includes(shipper)
          ).length ?? 0 != 0
      );
    }
    return blockers.length > 0;
  },
  getSingleEventDoesNotOverlap(stillEvent, movingEvent) {
    if (stillEvent.extendedProps.eventType != 'BlockerEvent') {
      return true;
    }
    if (stillEvent.start > movingEvent.start && stillEvent.end < movingEvent.end) {
      return true;
    }
    if (movingEvent.extendedProps.eventType == "StepEvent" && stillEvent.extendedProps.stepIds.find(x => movingEvent.extendedProps.stepIds.includes(x)) !== undefined)
    {
      return false;
    }
    if (movingEvent.extendedProps.eventType == "InstallationEvent") {
      var installersIdPublics =
        movingEvent.extendedProps.installerIds;
      if (stillEvent.extendedProps.selectedInstallers.filter((installer) =>
        installersIdPublics?.includes(installer)).length ?? 0 != 0) {
        return false;
      }
    }
    if (movingEvent.extendedProps.eventType == "DeliveryEvent" && stillEvent.extendedProps.selectedShippers.filter(
      (shipper) =>
        movingEvent.extendedProps.shipper != null && movingEvent.extendedProps.shipper.idPublic == shipper
    ).length != 0) {
      return false;
    }
    return true;
  },
  getNumberOfDaysUntilReference(event, blockerEvents, calculationConfig, step) {
    var referenceDate = null;
    if (step.backSchedulingReferenceDate == "Delivery") {
      referenceDate = new Date(event.extendedProps.workOrderDeliveryDate);
    }
    if (step.backSchedulingReferenceDate == "Installation") {
      referenceDate = new Date(event.extendedProps.workOrderInstallationDate);
    }
    if (step.backSchedulingReferenceDate == "Production") {
      referenceDate = new Date(event.extendedProps.workOrderProductionDate);
    }
    if (referenceDate == null) {
      return 0;
    }
    if (calculationConfig == "currentDate") {
      referenceDate = Vue.prototype.$dayjs(new Date()).format("YYYY-MM-DD");
    }
    return this.workedDaysForPeriod(event.start, referenceDate, step.includeSaturday, step.includeSunday, blockerEvents, Vue.prototype.$dayjs(event.start).isBefore(Vue.prototype.$dayjs(referenceDate)) ? 1 : -1, calculationConfig);
  },
  workedDaysForPeriod(eventStartDate, referenceDate, includeSaturday, includeSunday, blockerEvents, direction, calculationConfig) {
    var workedDays = 0;
    var currentDay = Vue.prototype.$dayjs(eventStartDate);
    var lastDay = new Date(referenceDate);
    while ((direction > 0 && new Date(currentDay).getTime() < lastDay.getTime()) || (direction < 0 && new Date(currentDay).getTime() > lastDay.getTime()) && workedDays <= 365) {
      currentDay = Vue.prototype.$dayjs(currentDay).add(1 * direction, "day");
      var saturdayValid = includeSaturday || currentDay.day() != 6;
      var sundayValid = includeSunday || currentDay.day() != 0;
      var isNotBlocked = !this.dayIsBlocked(new Date(currentDay), blockerEvents);
      if (saturdayValid && sundayValid && isNotBlocked) {
        workedDays++;
      }
    }
    if (calculationConfig == "currentDate") {
      let days = -workedDays * direction;
      if (days > 0) {
        return days - 1;
      }
      return days
    }
    return workedDays * direction;
  },
  dayIsBlocked(date, blockerEvents) {
    var isBlocked = false;
    var i = 0;
    while (!isBlocked && i < blockerEvents.length) {
      if (Vue.prototype.$dayjs(date).isSameOrAfter(Vue.prototype.$dayjs(blockerEvents[i].start)) && Vue.prototype.$dayjs(date).isBefore(Vue.prototype.$dayjs(blockerEvents[i].end))) {
        isBlocked = true;
      }
      i++;
    }
    return isBlocked;
  },
  updateEventFormat(info, moveBrokenLink, workOrder, shippers, installers) {
    // Separate old and new Data
    let newResource = info.newResource;
    let oldResource = info.oldResource;
    let newEvent = info.event;
    let oldEvent = info.oldEvent;
    let emptyInstallersList = false;
    let duplicatedInstallationEventInstallers = [];

    // If there is a resource, update it
    if (newResource) {
      if (newResource.id != oldResource.id && newResource.extendedProps.resourceType == oldResource.extendedProps.resourceType) {
        // Delivery logic
        if (oldResource.extendedProps.resourceType == "DeliveryEvent") {
          if (!newResource.id.includes('00000000-0000-0000-0000-0000000000000')) {
            workOrder.shipper = shippers.find(x => x.idPublic === newResource.id);
          } else {
            workOrder.shipper = null
          }
        }

        // Installer Logic
        if (oldResource.extendedProps.resourceType == "InstallationEvent") {
          if (!newResource.id.includes('00000000-0000-0000-0000-0000000000000') && oldEvent.extendedProps.isMainEvent) {
            // if event is dropped on a new resource and is main event
            if (info.jsEvent.shiftKey) {
              let installerFound = workOrder.installers.find(x => x.idPublic == newResource.extendedProps.resourceData.idPublic)
              if (!installerFound) {
                workOrder.installers.push(installers.find(x => x.idPublic === newResource.id));
              }
            } else {
              workOrder.installers = [installers.find(x => x.idPublic === newResource.id)];
            }
          } else if (!newResource.id.includes('00000000-0000-0000-0000-0000000000000') && !oldEvent.extendedProps.isMainEvent) {
            // if event is dropped on a new resource and is Not main event
            if (info.jsEvent.shiftKey) {
              let installerFound = newEvent.extendedProps.installers.find(x => x.idPublic == newResource.extendedProps.resourceData.idPublic)
              if (!installerFound) {
                newEvent.extendedProps.installers.push(installers.find(x => x.idPublic === newResource.id));
              }
            } else {
              duplicatedInstallationEventInstallers = [installers.find(x => x.idPublic === newResource.id)];
            }
          } else if (newResource.id.includes('00000000-0000-0000-0000-0000000000000') && oldEvent.extendedProps.isMainEvent) {
            // if event is dropped on UNASSIGNED and is main event
            newEvent.extendedProps.installers = [];
          } else if (newResource.id.includes('00000000-0000-0000-0000-0000000000000') && !oldEvent.extendedProps.isMainEvent) {
            // if event is dropped on UNASSIGNED and is Not main event
            emptyInstallersList = true;
          }
        }

      } else {
        console.log('no match');
      }
    }

    let endDate = newEvent.endStr !== "" ? newEvent.endStr : this.getEndDate(newEvent);

    let model = {
      id: newEvent.id,
      title: newEvent.title,
      allDay: newEvent.allDay,
      isMainEvent: oldEvent.extendedProps.isMainEvent,
      installers: emptyInstallersList ? []
        : duplicatedInstallationEventInstallers.length > 0 ? duplicatedInstallationEventInstallers
          : newEvent.extendedProps.installers,
      status: newEvent.extendedProps.status,
      startDate: Vue.prototype.$dayjs(newEvent.startStr).format('YYYY-MM-DD'),
      startTime: Vue.prototype.$dayjs(newEvent.start).format('HH:mm'),
      endDate: Vue.prototype.$dayjs(endDate).format('YYYY-MM-DD'),
      endTime: Vue.prototype.$dayjs(endDate).format('HH:mm'),
      comment: newEvent.extendedProps.comment,
      userCreated: oldEvent.extendedProps.userCreated,
      workOrder: workOrder,
      customHexColor: newEvent.extendedProps.customHexColor,
      // completePreviousProgressOnComplete:
      //   this.completePreviousStepsOnComplete,
      // Need to check values
      // values: newEvent.extendedProps.values.filter((v) => {
      //   return !(v.id == null && Number.isNaN(parseFloat(v.value)));
      // }),
      values: []
    };
    model.moveBrokenLink = moveBrokenLink;
    model.type = oldEvent.extendedProps.eventType.replace('Event', '').toLowerCase();

    // console.log('Formated model: ',model);

    return model;
  },
  getEndDate(event) {
    let endDate;
    if (event.end != null) {
      endDate = Vue.prototype.$dayjs(event.end).format("YYYY-MM-DDTHH:mm");
    } else if (event.allDay == false) {
      endDate = Vue.prototype.$dayjs(event.start)
        .add(1, "hour")
        .format("YYYY-MM-DDTHH:mm");
    } else if (event.allDay == true) {
      endDate = Vue.prototype.$dayjs(event.start)
        .add(1, "day")
        .format("YYYY-MM-DDTHH:mm");
    }

    return endDate;
  }
}